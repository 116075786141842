
















import {Vue, Component, Prop} from "vue-property-decorator";
import CommoditySucHeadType from "./indexType";

@Component({name: "CommoditySucHead"})
export default class CommoditySucHead extends Vue implements CommoditySucHeadType{

    get getPhoto(){
        if ( this.getData && "picture" in this.getData ){
            let ImgList;
            ImgList = this.getData.picture.split(";");
            return ImgList[0]
        }else{
            return this.getData.picture || require("@/assets/icon/Shop/TestShop.png");
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }
}
