






















import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import CommoditySucItemType from "./indexType";

@Component({name: "CommoditySucItem"})
export default class CommoditySucItem extends Vue implements CommoditySucItemType{

    get getPhoto(){
        if ( this.getData && this.getData.picture ){
            let ImgList;
            ImgList = this.getData.picture.split(";");
            return ImgList[0]
        }else{
            return this.getData.picture || require("@/assets/icon/Shop/TestShop.png");
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index || 0 }

    @Emit("pullClick")
    pullClick(){
        return {
            data:this.getData,
            index:this.getIndex
        }
    }
}
