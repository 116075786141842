























import {Vue, Component, Prop} from "vue-property-decorator";
import CommoditySucTitleType from "./indexType";

@Component({name: "CommoditySucTitle"})
export default class CommoditySucTitle extends Vue implements CommoditySucTitleType{

    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }

    @Prop(Object)
    shopData!:any
    get getShopData(){ return this.shopData || {} }
}
