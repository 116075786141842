


















import {Vue, Component, Watch, Emit} from "vue-property-decorator";
import CommoditySucListType from "@/views/Shop/CommoditySuc/components/List/indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import CommoditySucItem from "@/views/Shop/CommoditySuc/components/Item/index.vue";
import { IndexStore } from "@/store/modules/Index";
import { getComment } from "@/views/Shop/CommoditySuc/Server";
import {CommoditySucStore} from "@/views/Shop/CommoditySuc/Store";

@Component({name: "CommoditySucList",components:{ PullDownUpList,ListBottom,CommoditySucItem }})
export default class CommoditySucList extends Vue implements CommoditySucListType{
    Scroll = 0
    UpDownBool = false
    StopUp = false
    Forbid = true
    List:any[] = []

    scrollHeight = 0
    minHeight = 20
    app:any = {}

    activated(){
        this.init()
        let time = setTimeout(()=>{
            this.handleUpData(true)
            clearTimeout( time )
        })
    }

    init(){
        let app:any = document.getElementById("app");
        this.app = app
        let boxHeight = app.offsetHeight,
            contentHeight = app.scrollHeight;
        if ( contentHeight > boxHeight ){
            this.scrollHeight = contentHeight - boxHeight
            app.addEventListener("scroll",this.handleScroll,{ passive: false })
        }else{
            app.removeEventListener("scroll",this.handleScroll,{ passive: false })
        }
    }

    handelGetClick(data:any){
        let upData = CommoditySucStore.getCommodityUpData
        upData.pageNo = 1
        CommoditySucStore.SetCommodityUpData(upData)
        this.Scroll = 1
        this.Forbid = true
        IndexStore.SetScrollStopBool(false)
        this.pullData(data)
    }

    handleScroll(e:any){
        let { scrollTop } = e.target
        this.init()
        if ( scrollTop >= (this.scrollHeight - this.minHeight) ){
            this.Forbid = false
            this.app.scrollTop = scrollTop
            IndexStore.SetScrollStopBool(true)
        }else{
            this.Forbid = true
            IndexStore.SetScrollStopBool(false)
        }
        e.preventDefault()
        e.stopPropagation()
        return false
    }

    handleUpDown(): void {
        if ( this.StopUp )return
        let upData = CommoditySucStore.getCommodityUpData
        upData.pageNo += 1
        CommoditySucStore.SetCommodityUpData( upData )
        this.handleUpData()
    }

    handleUpData(bool=false): void {
        getComment().then(res=>{
            this.UpDownBool = false
            if ( res.length < CommoditySucStore.getCommodityUpData.pageSize )this.StopUp = true;
            if ( bool )
                CommoditySucStore.SetCommodityList( res );
            else
                CommoditySucStore.SetCommodityList( CommoditySucStore.getCommodityList.concat(res) );
        })
    }

    get getList(){
        return CommoditySucStore.getCommodityList
    }
    @Watch("getList")
    changeGetList(newVal:any[]){
        this.List = newVal
    }

    @Emit("pullData")
    pullData(data:any){
        return data
    }
}
