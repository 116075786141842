



















import {Vue, Component} from "vue-property-decorator";
import CommoditySucType from "./CommoditySuc";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import ZoomPage from "@/impView/PageSafety";
import CommoditySucHead from "@/views/Shop/CommoditySuc/components/Head/index.vue";
import CommoditySucTitle from "@/views/Shop/CommoditySuc/components/Title/index.vue";
import CommoditySucToShop from "@/views/Shop/CommoditySuc/components/ToShop/index.vue";
import CommoditySucProductSuc from "@/views/Shop/CommoditySuc/components/ProductSuc/index.vue";
import CommoditySucList from "@/views/Shop/CommoditySuc/components/List/index.vue";
import {CommoditySucStore} from "@/views/Shop/CommoditySuc/Store";

@Component({name: "CommoditySuc",components:{ HeadTop,CommoditySucHead,CommoditySucTitle,CommoditySucToShop,CommoditySucProductSuc,CommoditySucList }})
export default class CommoditySuc extends ZoomPage implements CommoditySucType{
    ShopData:any = {}
    data:any = {}

    mounted(){
        let { data,shopData } = this.$route.query
        let jsonData:any = {},jsonShopData:any = {}
        try{
            jsonData = JSON.parse( data as string )
            jsonShopData = JSON.parse( shopData as string )
            this.data = jsonData.data
            this.ShopData = jsonShopData
            let upData = CommoditySucStore.getCommodityUpData
            upData.storeId = jsonShopData.id
            upData.id = jsonData.data && jsonData.data.id || 1
            CommoditySucStore.SetCommodityUpData(upData)
            console.log( jsonData,jsonShopData )
        }catch (e) {
            console.info("转换失败")
        }
    }

    handleGetPullData(data:any){
        let app:any = document.getElementById("app");
        app.style = `transition:.3s;`
        app.scrollTop = 0
        this.data = data.data
    }

}
