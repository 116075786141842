





















import {Vue, Component, Prop} from "vue-property-decorator";
import CommoditySucToShopType from "./indexType";

@Component({name: "CommoditySucToShop"})
export default class CommoditySucToShop extends Vue implements CommoditySucToShopType{
    rightIcon = require("@/assets/icon/Basics/Right.png")

    handleClickBack(){
        this.$router.go(-1)
    }

    get getPhoto(){
        return this.getData && this.getData.image || require("@/assets/icon/Shop/TestShop.png")
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }
}
