








import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import CommoditySucProductSucType from "./indexType";

@Component({name: "CommoditySucProductSuc"})
export default class CommoditySucProductSuc extends Vue implements CommoditySucProductSucType{
    ImgList = [ ]
    show = false

    mounted(){
        this.initData()
    }

    initData(){
        if ( JSON.stringify( this.getData ) == "{}" )return;
        if ( this.getData.picture ){
            try{
                let ImgSrc = this.getData.picture.split(";");
                ImgSrc.splice(0,1);
                this.ImgList = ImgSrc
                this.show = ImgSrc.length ? true : false
            }catch (e) { }
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }
    @Watch("getData")
    changeData(newVal:any){
        this.initData()
    }
}
